import React, { FC } from "react";
import { logout } from "../utils/auth";
import { PlaceholderMessage } from "../components/PlaceholderMessage";

const Logout: FC = () => {
  logout();

  return <PlaceholderMessage>Logging out...</PlaceholderMessage>;
};

export default Logout;
